/* src/style/Toast.css */
.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    max-width: 90%;
    direction: rtl;
  }
  
  @media (max-width: 768px) {
    .toast-container {
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
    }
  }
  
  .toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0vh 2vw;
    background-color: var(--background-color-navbar);
    color: var(--primary-text-color);
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0; /* התחלתי בלתי נראה */
    transform: translateX(100%); /* התחלתי מחוץ למסך */
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  /* כאשר ה-toast מופיע */
  .toast.show {
    opacity: 1;
    transform: translateX(0); /* מחזיר את הטוסט למסך */
  }
  
  /* כאשר ה-toast נעלם */
  .toast.hide {
    opacity: 0;
    transform: translateX(100%); /* מוציא את הטוסט מחוץ למסך */
  }
  
  .toast-info {
    border-left: 4px solid #007bff;
  }
  
  .toast-success {
    border-left: 4px solid #28a745;
  }
  
  .toast-error {
    border-left: 4px solid #dc3545;
  }
  
  .toast-close {
    cursor: pointer;
    color: var(--primary-text-color);
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .toast-close:hover {
    color: var(--dangerous-color);
  }
  